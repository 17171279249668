import * as ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { useEffect, useState, createElement } from "react";
import { graphql } from "gatsby";

import { isBrowser, edgesToArray, getAllUrlParams } from "../../context/helpers";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import useDetectElementLoaded from "../../hooks/useDetectElementLoaded";
import { useCurrentLang } from "../../hooks/useLocalizedCartInfo";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import InstagramIcon from "../../components/icons/svgs/instagram.svg";
import TikTokIcon from "../../components/icons/svgs/tiktok-fill-square.svg";
import FacebookIcon from "../../components/icons/svgs/facebook-round.svg";

import ALCustomLandingPage from "../../components/al_components/ALCustomLandingPage";
import SEO from "../../components/seo";

import "./track.scss";

const socialMediaElements = [
  {
    link: "https://www.instagram.com/analuisany",
    title: "Instagram",
    image: <InstagramIcon width="33" fill="#000" stroke="white" />,
  },
  {
    link: "https://www.tiktok.com/@analuisany",
    title: "TikTok",
    image: <TikTokIcon width="30" />,
  },
  {
    link: "https://www.facebook.com/analuisanewyork",
    title: "Facebook",
    image: <FacebookIcon width="30" />,
  },
];

function StayConnected({ withBorders = false, message }) {
  return (
    <div className={`stay-connected ${withBorders ? "borders-container" : ""}`}>
      <div className="stay-connected-title">{message}</div>
      <div className="stay-connected-icons">
        {socialMediaElements.map((social) => (
          <a
            href={social.link}
            title={social.title}
            target="_blank"
            key={`stay_connected_${social.title}`}
            rel="noreferrer"
          >
            {social.image}
          </a>
        ))}
      </div>
    </div>
  );
}

function useTrackRefreshParcelLab() {
  const dict = useLocalizedSentenceDict();

  const isPlBoxLoaded = useDetectElementLoaded({
    elementClass: "pl-box",
    stopObservingAfter: 30 * 1000,
  });

  const isPlBoxArticlesLoaded = useDetectElementLoaded({
    elementClass: "pl-box-articles",
    stopObservingAfter: 30 * 1000,
  });

  const isMainBoxLoaded = useDetectElementLoaded({
    elementID: "pl-main-box",
    detectChildren: true,
    stopObservingAfter: 30 * 1000,
  });

  useEffect(() => {
    function updateStayConnectedBlock() {
      const stayConnectedContainer = document.createElement("div");
      stayConnectedContainer.id = "stay-connected-container";

      if (document.getElementsByClassName("pl-box-articles").length) {
        document.getElementById("stay-connected-container")?.remove();

        document.getElementsByClassName("pl-box-articles")[0]?.appendChild(stayConnectedContainer);

        ReactDOM.render(
          <StayConnected message={dict.get("Stay connected")} />,
          stayConnectedContainer
        );
      } else if (document.getElementsByClassName("pl-box").length) {
        document.getElementById("stay-connected-container")?.remove();

        document.getElementsByClassName("pl-box")[0]?.appendChild(stayConnectedContainer);

        ReactDOM.render(
          <StayConnected withBorders message={dict.get("Stay connected")} />,
          stayConnectedContainer
        );
      }
    }

    if (isPlBoxArticlesLoaded || isPlBoxLoaded) {
      updateStayConnectedBlock();
    }
  }, [isPlBoxArticlesLoaded, isPlBoxLoaded]);

  useEffect(() => {
    function updateCustomContent() {
      if (document.querySelector("#pl-main-box > div.pl-col.pl-col-8 > div")) {
        const _customDiv = "<div id='pl-custom-content'></div>";
        const _lastDomElement = document.querySelector("#pl-main-box > div.pl-col.pl-col-8");

        _lastDomElement.innerHTML += _customDiv;

        // select empty div to override
        const _customElement = document.getElementById("pl-custom-content");

        // create react element
        const element = createElement("div", null, "Placeholder Social");
        ReactDOM.render(element, _customElement);
      }
    }

    if (isMainBoxLoaded) {
      updateCustomContent();
    }
  }, [isMainBoxLoaded]);
}

const loadScript = (url) =>
  new Promise((resolve, reject) => {
    if (!document) {
      reject(new Error("Document was not defined"));
    }
    const tag = document.getElementsByTagName("script")[0];
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src = url;
    script.async = true;
    script.onreadystatechange = () => {
      resolve(script);
    };
    script.onload = script.onreadystatechange;

    script.onerror = (msg) => {
      console.log(msg);
      reject(new Error("Error loading script."));
    };

    script.onabort = (msg) => {
      console.log(msg);
      reject(new Error("Script loading aboirted."));
    };

    if (tag.parentNode != null) {
      tag.parentNode.insertBefore(script, tag);
    }
  });

function ParcelLabPage({ path, data }) {
  const [isParcelLabLoaded, updateParcelLabLoaded] = useState(false);
  const lang = useCurrentLang();
  useTrackRefreshParcelLab();

  useEffect(() => {
    if (isParcelLabLoaded) {
      const orderNumber = getAllUrlParams().orderNo;

      if (orderNumber) {
        const inputElement = document.querySelector(
          "#pl-on-rendered-wrapper > div.pl-search > form > div > div > div.pl-col.pl-col-5 > input"
        );
        if (inputElement) {
          inputElement.value = orderNumber;
          inputElement.dispatchEvent(new Event("input", { bubbles: true }));
        }
      }
    }
  }, [isParcelLabLoaded]);

  useEffect(() => {
    if (isBrowser) {
      loadScript("https://cdn.parcellab.com/js/v5/main.min.js")
        .then(() => {
          updateParcelLabLoaded(true);
          const options = {
            userId: 1617842,
            show_searchForm: true,
            show_zipCodeInput: true,
            show_articleList: true,
          };
          window.parcelLabTrackAndTrace.initialize(options);
          const linkTag = document.createElement("link");
          linkTag.rel = "stylesheet";
          linkTag.href = "https://cdn.parcellab.com/css/v5/main.min.css";
          document.getElementsByTagName("head")[0].appendChild(linkTag);

          updateParcelLabLoaded(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  let landingPageContent = null;
  if (data?.allContentfulLandingPage) {
    const allBlocksArray = edgesToArray(data.allContentfulLandingPage);
    const defaultContent = allBlocksArray.find((n) => n.node_locale === "en-US");
    const blocksRoot = allBlocksArray.find((b) => b.node_locale === lang);
    landingPageContent = blocksRoot ?? defaultContent;
  }

  return (
    <>
      <SEO
        title="Track your order | Ana Luisa Jewelry"
        description="Looking to track your order from Ana Luisa? Our step-by-step guide will help you easily track your package and stay updated on its delivery status. Shop with confidence at analuisa.com and enjoy a seamless shopping experience."
      />
      <Helmet>
        <link rel="canonical" href={`https://www.analuisa.com${path}`} />
        <meta property="og:url" content={`https://www.analuisa.com${path}`} />
      </Helmet>
      <h1 className="h1 h1--bold center parcellab-track--title">TRACK MY ORDER</h1>
      <div
        id="parcellab-track-and-trace"
        className={!isParcelLabLoaded ? "parcellab-track-and-trace--loading" : ""}
      >
        <img
          className="parcellab-loader"
          src="https://cdn.parcellab.com/img/loading-spinner-1.gif"
          alt="loading"
        />
      </div>

      <ALCustomLandingPage data={landingPageContent} />
    </>
  );
}

export default withALErrorBoundary({
  name: "ParcelLabPage",
  priority: "P3",
})(ParcelLabPage);

export const query = graphql`
  query ($id: String!) {
    allContentfulLandingPage(filter: { handle: { eq: $id } }, limit: 1) {
      edges {
        node {
          node_locale
          title
          blocks {
            ... on Node {
              ...ContentfulBannerBlockFields
              ...ContentfulSimpleTextCtaBlockFields
              ... on ContentfulHomePageBrowseProducts {
                __typename
                contentful_id
                title
                collectionHandle
              }
              ...ContentfulPopularCollectionsBlockFields
              ...ContentfulReviewsBlockFields
              ...ContentfulScrollableImagesBlockFields
              ...ContentfulUgcVideosBlockFields
              ...ContentfulUspsBlockFields
              ...ContentfulTileCategoriesBlockFields
              ...ContentfulLpImageBlockFields
              ...ContentfulLpTextBlockFields
              ...ContentfulTextImageBlockFields
              ...ContentfulProductsCarouselFields
              ...ContentfulTextAndImagesBlockFields
              ... on ContentfulLandingPageSpacing {
                __typename
                contentful_id
                spacingDesktop
                spacingMobile
              }
            }
          }
        }
      }
    }
  }
`;
